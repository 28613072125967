import { Route, Routes } from 'react-router-dom'
import { NotFoundPage } from 'pages/not-found'
import MainLayout from 'features/main-layout'
import { NavigationPathes } from 'shared/constants'
import { WithdrawalsPage } from 'pages/withdrawals-page'
import { ReferralsWithdrawalsStatisticsPage } from 'pages/referrals-withdrawals-statistics-page'

export const AppRoutes = () => {
  return (
    <Routes>
      <Route element={<MainLayout />}>
        <Route
          path={NavigationPathes.HOME}
          element={<>Welcome to Admin dashboard!</>}
        />

        <Route
          path={NavigationPathes.WITHDRAWALS}
          element={<WithdrawalsPage />}
        />

        <Route
          path={NavigationPathes.WITHDRAWALS_STATISTICS}
          element={<ReferralsWithdrawalsStatisticsPage />}
        />

        <Route
          path='*'
          element={<NotFoundPage />}
        />
      </Route>
    </Routes>
  )
}
