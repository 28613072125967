import { Typography, colors } from '@mui/material'
import React from 'react'
import { User as UserIcon } from 'shared/icons'
import { StyledLink } from 'shared/styles/styles'
import {
  StyledNavbarLayoutItem,
  StyledNavbarLayoutItemDsiabled
} from '../styles'

interface Props {
  path: string
  name: string
  isActive: boolean
}
export const MainLayoutNavbarItem: React.FC<Props> = ({
  path,
  name,
  isActive
}) => {
  if (!isActive) {
    return (
      <StyledNavbarLayoutItemDsiabled>
        <UserIcon
          width={'1.5rem'}
          height={'1.5rem'}
          color={colors.common.black}
        />
        <Typography
          sx={{
            color: colors.common.black,
            fontSize: '1rem'
          }}
        >
          {name}
        </Typography>
      </StyledNavbarLayoutItemDsiabled>
    )
  }

  return (
    <StyledLink to={path}>
      <StyledNavbarLayoutItem>
        <UserIcon
          width={'1.5rem'}
          height={'1.5rem'}
          color={colors.common.black}
        />
        <Typography
          sx={{
            color: colors.common.black,
            fontSize: '1rem'
          }}
        >
          {name}
        </Typography>
      </StyledNavbarLayoutItem>
    </StyledLink>
  )
}
