import { Typography } from '@mui/material'
import React from 'react'
import { XyroTokenColored } from 'shared/icons'
import {
  StyledMainLayoutNavbarLogoContainer,
  StyledMainLayoutNavbarWrapper
} from '../styles'
import { NAVBAR_MENU_TABS } from '../constants'
import { MainLayoutNavbarItems } from './main-layout-navbar-items'

export const MainLayoutNavbar: React.FC = () => {
  return (
    <StyledMainLayoutNavbarWrapper>
      <StyledMainLayoutNavbarLogoContainer>
        <XyroTokenColored
          width={'3rem'}
          height={'3rem'}
        />
        <Typography sx={{ fontSize: '2rem', fontWeight: '400' }}>
          XYRO
        </Typography>
      </StyledMainLayoutNavbarLogoContainer>

      {NAVBAR_MENU_TABS.map(el => {
        return (
          <MainLayoutNavbarItems
            key={el.sectionName}
            sectionName={el.sectionName}
            navbarTabs={el.items}
          />
        )
      })}
    </StyledMainLayoutNavbarWrapper>
  )
}
