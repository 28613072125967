import { Box, Typography } from '@mui/material'
import React from 'react'
import { NavbarTab } from '../types'
import { StyledNavbarLayoutItems } from '../styles'
import { MainLayoutNavbarItem } from './main-layout-navbar-item'

interface Props {
  sectionName: string
  navbarTabs: NavbarTab[]
}

export const MainLayoutNavbarItems: React.FC<Props> = ({
  sectionName,
  navbarTabs
}) => {
  return (
    <Box sx={{ pl: 3, mt: 2 }}>
      <Typography
        sx={{ fontSize: '1.25rem', fontWeight: 'medium', mb: '0.5rem' }}
      >
        {sectionName}
      </Typography>
      <StyledNavbarLayoutItems>
        {navbarTabs.map((el, index) => {
          return (
            <MainLayoutNavbarItem
              key={index}
              name={el.name}
              path={el.path}
              isActive={el.isActive}
            />
          )
        })}
      </StyledNavbarLayoutItems>
    </Box>
  )
}
