import { NavigationPathes } from 'shared/constants'

export const NAVBAR_MENU_TABS = [
  {
    sectionName: 'General',
    items: [
      {
        path: NavigationPathes.WITHDRAWALS,
        name: 'Profile',
        isActive: false
      }
    ]
  },
  {
    sectionName: 'Finances',
    items: [
      {
        path: NavigationPathes.WITHDRAWALS,
        name: 'Ref. Withdrawals',
        isActive: true
      },
      {
        path: NavigationPathes.WITHDRAWALS_STATISTICS,
        name: 'Stats. Withdrawals',
        isActive: true
      }
    ]
  },
  {
    sectionName: 'Users',
    items: [
      {
        path: NavigationPathes.WITHDRAWALS,
        name: 'Users',
        isActive: false
      },
      {
        path: NavigationPathes.WITHDRAWALS,
        name: 'Admins',
        isActive: false
      }
    ]
  }
]
