/* eslint-disable */
import * as types from './graphql';
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';

/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel or swc plugin for production.
 */
const documents = {
    "\n  query me {\n    me {\n      __typename\n      id\n      name\n      bio\n      role\n      createdAt\n      avatarKeys\n      discordRoles\n      avatarUris\n      isInfluencer\n      discord {\n        id\n        name\n      }\n      wallet {\n        address\n      }\n    }\n  }\n": types.MeDocument,
    "\n  query getReferralWithdrawRequestsStatistic {\n    getReferralWithdrawRequestsStatistic {\n      oweAmount\n      openAmount\n      pendingAmount\n      paidAmount\n    }\n  }\n": types.GetReferralWithdrawRequestsStatisticDocument,
    "\n  mutation approveWithdrawRequest(\n    $data: ReferralWithdrawRequestOperationInput!\n  ) {\n    approveWithdrawRequest(data: $data) {\n      id\n    }\n  }\n": types.ApproveWithdrawRequestDocument,
    "\n  mutation closeWithdrawRequest ($data: CloseReferralWithdrawRequestOperationInput!) {\n    closeWithdrawRequest(\n      data: $data\n    ) {\n      id\n    }\n  }\n": types.CloseWithdrawRequestDocument,
    "\n  query getReferralWithdrawRequests($filters: ReferralWithdrawRequestsFilters!, $pagination: PaginatedInput!) {\n    getReferralWithdrawRequests(\n      filters: $filters,\n      pagination: $pagination\n    ) {\n      skip\n      take\n      total\n      requests {\n        id\n        status\n        amount\n        cancelReason\n        transactionHash\n        createdAt\n        updatedAt\n        user {\n          id\n          name\n          avatarUris\n          wallet {\n            address\n          }\n        }\n      }\n    }\n  }\n": types.GetReferralWithdrawRequestsDocument,
    "\n  mutation rejectWithdrawRequest(\n    $data: CancelReferralWithdrawRequestOperationInput!\n  ) {\n    rejectWithdrawRequest(data: $data) {\n      id\n    }\n  }\n": types.RejectWithdrawRequestDocument,
};

/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 *
 *
 * @example
 * ```ts
 * const query = gql(`query GetUser($id: ID!) { user(id: $id) { name } }`);
 * ```
 *
 * The query argument is unknown!
 * Please regenerate the types.
 */
export function gql(source: string): unknown;

/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query me {\n    me {\n      __typename\n      id\n      name\n      bio\n      role\n      createdAt\n      avatarKeys\n      discordRoles\n      avatarUris\n      isInfluencer\n      discord {\n        id\n        name\n      }\n      wallet {\n        address\n      }\n    }\n  }\n"): (typeof documents)["\n  query me {\n    me {\n      __typename\n      id\n      name\n      bio\n      role\n      createdAt\n      avatarKeys\n      discordRoles\n      avatarUris\n      isInfluencer\n      discord {\n        id\n        name\n      }\n      wallet {\n        address\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query getReferralWithdrawRequestsStatistic {\n    getReferralWithdrawRequestsStatistic {\n      oweAmount\n      openAmount\n      pendingAmount\n      paidAmount\n    }\n  }\n"): (typeof documents)["\n  query getReferralWithdrawRequestsStatistic {\n    getReferralWithdrawRequestsStatistic {\n      oweAmount\n      openAmount\n      pendingAmount\n      paidAmount\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation approveWithdrawRequest(\n    $data: ReferralWithdrawRequestOperationInput!\n  ) {\n    approveWithdrawRequest(data: $data) {\n      id\n    }\n  }\n"): (typeof documents)["\n  mutation approveWithdrawRequest(\n    $data: ReferralWithdrawRequestOperationInput!\n  ) {\n    approveWithdrawRequest(data: $data) {\n      id\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation closeWithdrawRequest ($data: CloseReferralWithdrawRequestOperationInput!) {\n    closeWithdrawRequest(\n      data: $data\n    ) {\n      id\n    }\n  }\n"): (typeof documents)["\n  mutation closeWithdrawRequest ($data: CloseReferralWithdrawRequestOperationInput!) {\n    closeWithdrawRequest(\n      data: $data\n    ) {\n      id\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query getReferralWithdrawRequests($filters: ReferralWithdrawRequestsFilters!, $pagination: PaginatedInput!) {\n    getReferralWithdrawRequests(\n      filters: $filters,\n      pagination: $pagination\n    ) {\n      skip\n      take\n      total\n      requests {\n        id\n        status\n        amount\n        cancelReason\n        transactionHash\n        createdAt\n        updatedAt\n        user {\n          id\n          name\n          avatarUris\n          wallet {\n            address\n          }\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query getReferralWithdrawRequests($filters: ReferralWithdrawRequestsFilters!, $pagination: PaginatedInput!) {\n    getReferralWithdrawRequests(\n      filters: $filters,\n      pagination: $pagination\n    ) {\n      skip\n      take\n      total\n      requests {\n        id\n        status\n        amount\n        cancelReason\n        transactionHash\n        createdAt\n        updatedAt\n        user {\n          id\n          name\n          avatarUris\n          wallet {\n            address\n          }\n        }\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation rejectWithdrawRequest(\n    $data: CancelReferralWithdrawRequestOperationInput!\n  ) {\n    rejectWithdrawRequest(data: $data) {\n      id\n    }\n  }\n"): (typeof documents)["\n  mutation rejectWithdrawRequest(\n    $data: CancelReferralWithdrawRequestOperationInput!\n  ) {\n    rejectWithdrawRequest(data: $data) {\n      id\n    }\n  }\n"];

export function gql(source: string) {
  return (documents as any)[source] ?? {};
}

export type DocumentType<TDocumentNode extends DocumentNode<any, any>> = TDocumentNode extends DocumentNode<  infer TType,  any>  ? TType  : never;