import styled from '@emotion/styled'
import { Link } from 'react-router-dom'

export const itemsCentered = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
}

export const StyledLink = styled(Link)`
  text-decoration: none;
  width: 100%;
`
