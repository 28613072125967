import { gql } from '__generated__'

export const GET_ME = gql(`
  query me {
    me {
      __typename
      id
      name
      bio
      role
      createdAt
      avatarKeys
      discordRoles
      avatarUris
      isInfluencer
      discord {
        id
        name
      }
      wallet {
        address
      }
    }
  }
`)
