import styled from '@emotion/styled'
import { Box, colors } from '@mui/material'
import { customColors } from 'shared/styles/colors'

export const StyledMainLayoutWrapper = styled(Box)`
  display: grid;
  height: 100%;
  max-height: 100vh;
  width: 100%;
  grid-template:
    'nav content' auto
    'nav footer' min-content / min-content minmax(0, auto);
`

export const StyledContentContainer = styled(Box)`
  grid-area: content;
  max-height: 100vh;
  display: flex;
  flex-direction: column;
  overflow: scroll;
  padding-top: 5rem;
`

export const StyledMainLayoutNavbarWrapper = styled(Box)`
  min-width: 16.5rem;
  height: 100vh;
  background-color: ${colors.common.white};
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
`

export const StyledMainLayoutNavbarLogoContainer = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: start;
  padding: 1rem;
  gap: 0.5rem;
  width: 100%;
  height: 5rem;
  border-bottom: 1px solid rgb(210, 210, 210);
`

export const StyledMainLayoutHeaderComponent = styled(Box)`
  width: 100%;
  height: 5rem;
  background-color: ${colors.common.white};
  border-bottom: 1px solid rgb(210, 210, 210);
  position: absolute;
  top: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  padding-left: 0.5rem;
`

export const StyledNavbarLayoutItems = styled(Box)`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  gap: 0.5rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
`

export const StyledNavbarLayoutItem = styled(Box)`
  padding: 0.5rem;
  height: 3rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 1rem;
  border-radius: 1rem;

  &:hover {
    background-color: ${customColors.lightBlue};
  }
`

export const StyledNavbarLayoutItemDsiabled = styled(Box)`
  padding: 0.5rem;
  height: 3rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 1rem;
  border-radius: 1rem;
  opacity: 0.5;
  cursor: not-allowed;

  &:hover {
    background-color: ${customColors.lightBlue};
  }
`
