import { useQuery } from '@apollo/client'
import { User } from '__generated__/graphql'
import { GET_ME } from 'api/auth/me'
import { useEffect } from 'react'
import { globalLoaderVar, userVar } from 'shared/store/auth.store'
import { URLLocation } from 'shared/types'

const MAIN_PROJECT_URL = 'https://xyro.io'

export const useAuthSession = () => {
  const { data, loading } = useQuery(GET_ME)

  useEffect(() => {
    const userData = data?.me || null

    if (!loading && (!userData || userData?.role !== 'ADMIN')) {
      window.location = MAIN_PROJECT_URL as URLLocation
      return
    }

    userVar(userData as User)
  }, [data?.me])

  useEffect(() => {
    globalLoaderVar(loading)
  }, [loading])
}
