import { Head } from 'features/head'
import { Suspense, lazy } from 'react'

const Withdrawals = lazy(() => import('features/referrals-withdrawals'))

export const WithdrawalsPage: React.FC = () => {
  return (
    <>
      <Head title='Withdrawals' />
      <Suspense fallback={<p>Loading...</p>}>
        <Withdrawals />
      </Suspense>
    </>
  )
}
