import { __DEV__ } from '@apollo/client/utilities/globals'
import { ErrorPage } from 'pages/error'
import { ErrorBoundary } from 'react-error-boundary'
import { loadDevMessages, loadErrorMessages } from '@apollo/client/dev'
import 'shared/styles/global.css'
import { ThemeProvider } from '@mui/material'
import { useReactiveVar } from '@apollo/client'
import { themeVar } from 'shared/store/theme.store'
import { useAuthSession } from 'shared/hooks/use-auth-session'
import { globalLoaderVar } from 'shared/store/auth.store'
import { AppRoutes } from './app-routes'

if (__DEV__) {
  loadDevMessages()
  loadErrorMessages()
}

const App: React.FC = () => {
  const isAppLoading = useReactiveVar(globalLoaderVar)

  useAuthSession()

  const appTheme = useReactiveVar(themeVar)
  return (
    <ErrorBoundary FallbackComponent={ErrorPage}>
      <ThemeProvider theme={appTheme}>
        {!isAppLoading ? <AppRoutes /> : 'Loading...'}
      </ThemeProvider>
    </ErrorBoundary>
  )
}
export default App
