import { Outlet } from 'react-router-dom'
import { ErrorBoundary } from 'react-error-boundary'
import { ErrorPage } from 'pages/error'
import { Box } from '@mui/material'
import { StyledContentContainer, StyledMainLayoutWrapper } from '../styles'
import { MainLayoutNavbar } from './main-layout-navbar'
import { MainLayoutHeader } from './main-layout-header'

export const MainLayout: React.FC = () => {
  return (
    <StyledMainLayoutWrapper>
      <MainLayoutNavbar />
      <StyledContentContainer>
        <MainLayoutHeader />
        <Box sx={{ p: 1 }}>
          <ErrorBoundary FallbackComponent={ErrorPage}>
            <Outlet />
          </ErrorBoundary>
        </Box>
      </StyledContentContainer>
    </StyledMainLayoutWrapper>
  )
}
