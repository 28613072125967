import { Head } from 'features/head'
import { Suspense, lazy } from 'react'

const ReferralsWithdrawalsStatistics = lazy(
  () => import('features/referrals-withdrawals-statistics')
)

export const ReferralsWithdrawalsStatisticsPage: React.FC = () => {
  return (
    <>
      <Head title='Withdrawals Statistics' />
      <Suspense fallback={<p>Loading...</p>}>
        <ReferralsWithdrawalsStatistics />
      </Suspense>
    </>
  )
}
