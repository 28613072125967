import React from 'react'
import { Typography } from '@mui/material'
import { useReactiveVar } from '@apollo/client'
import { userVar } from 'shared/store/auth.store'
import { StyledMainLayoutHeaderComponent } from '../styles'

export const MainLayoutHeader: React.FC = () => {
  const user = useReactiveVar(userVar)
  const userName = user?.name || ''

  return (
    <StyledMainLayoutHeaderComponent>
      <Typography sx={{ fontSize: '1.25rem' }}>
        Logged in as: <b>{userName}</b>
      </Typography>
    </StyledMainLayoutHeaderComponent>
  )
}
